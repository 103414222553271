import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import styles from "./index.css";
import logo from "./images/logo.png"
import { Analytics } from '@vercel/analytics/react';

import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "/unity/Build/WebBuild.loader.js",
    dataUrl: "/unity/Build/WebBuild.data",
    frameworkUrl: "/unity/Build/WebBuild.framework.js",
    codeUrl: "/unity/Build/WebBuild.wasm",
  });

    // We'll use a state to store the device pixel ratio.
    const [devicePixelRatio, setDevicePixelRatio] = useState(
      window.devicePixelRatio
    );

    useEffect(
      function () {
        // A function which will update the device pixel ratio of the Unity
        // Application to match the device pixel ratio of the browser.
        const updateDevicePixelRatio = function () {
          setDevicePixelRatio(window.devicePixelRatio);
        };
        // A media matcher which watches for changes in the device pixel ratio.
        const mediaMatcher = window.matchMedia(
          `screen and (resolution: ${devicePixelRatio}dppx)`
        );
        // Adding an event listener to the media matcher which will update the
        // device pixel ratio of the Unity Application when the device pixel
        // ratio changes.
        mediaMatcher.addEventListener("change", updateDevicePixelRatio);
        return function () {
          // Removing the event listener when the component unmounts.
          mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
        };
      },
      [devicePixelRatio]
    );

    const [loadingPercentage, setLoadingPercentage] = useState(
      Math.round(loadingProgression * 100) + "%"
    );

    useEffect(
      function () {
        const updateLoadingPercentage = function () {
          setLoadingPercentage(Math.round(loadingProgression * 100) + "%");
        };
      },
      [loadingPercentage]
    );

  return (
    <Fragment >
      {!isLoaded && (
        
        <div className="flex flex-col justify-center items-center min-h-screen">
          <img className=" mb-10 " src={logo} alt="logo" width={600} height={600} />
          <div className="flex w-6/12 bg-gray-200 rounded-full h-2 dark:bg-gray-700 mt-4">
            <div className="bg-gray-100 h-2 rounded-full" style={{ width: `${Math.round(loadingProgression * 100)}%` }}></div>
          </div>
        </div>
      )}

      <div className="flex content-center justify-center">
       
      <Unity className="w-full h-full m-12"
        unityProvider={unityProvider}
        style={{ visibility: isLoaded ? "visible" : "hidden", width: "100%", height: "100%"}}
        devicePixelRatio={devicePixelRatio}
      />
      </div>
      
      <Analytics />

    </Fragment>
  );
}

export default App;
